
import { defineComponent } from "vue";
import Loading from "@/components/Basic/Loading.vue";
import { ElMessage, ElLoading } from "element-plus";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadString,
  // uploadBytesResumable,
} from "firebase/storage";
import _ from "lodash";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "CreateAccount",
  components: {
    Loading,
  },
  created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }
    /*
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const userData = localStorage.getItem("userData");
            if (!userData) {
              auth.signOut();
              this.$store.commit("auth/setUserLogin", null, { root: true });
            }
            this.$router.replace("/");
          } else {
            localStorage.removeItem("userData");
          }
        });
        //*/
    this.$store.dispatch("auth/initSignupFormDetails", null, { root: true });
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      ElMessage.error(value);
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
    loadingStatus(): any {
      return this.$store.getters.getLoadingStatus;
    },
    error(): any {
      return this.$store.getters["auth/error"];
    },
    // form() {
    //   return this.$store.getters["CreateAccountForm/formValues"];
    // },
  },
  data() {
    return {
      // createSteb: "create_email", // create_email
      loadingImageFile: false,
      form: {
        // color: "#ffffff",
        color: "#000000", // default company color
        imageFile: "",
        companyUploadedFile: new File([], ""),
        companyUploadedFileDataUrl: "",
        companyLogoUrl: "",
      },
      hiresPerYears: [
        "10 - 49",
        "50 - 199",
        "200 - 499",
        "500 - 999",
        "more than 1,000",
      ],
      formValidate: {
        color: false,
        imageFile: false,
        sendFreeStuff: true,
      },
    };
  },
  mounted() {
    if (this.userLogin) {
      console.log("Your has been login!");
      this.$router.push("/");
    }
  },
  methods: {
    handleClickAuthProvider(provider: string) {
      this.signupByProvider(provider);
    },
    signupByProvider(provider: string) {
      const authData = {
        email: null,
        provider: provider,
      };
      this.$store.dispatch("auth/signup", authData, { root: true });
    },
    loginWithGoogle() {
      this.$store.dispatch("userLoginWidthGoogle", true);
    },
    handleAvatarSuccess(res: any) {
      this.form.imageFile = URL.createObjectURL(res.file);

      const reader = new FileReader();
      reader.addEventListener("load", (/*event: ProgressEvent*/) => {
        this.form.companyUploadedFile = res.file;
        this.form.companyUploadedFileDataUrl = String(reader.result);
      });
      reader.readAsDataURL(res.file);

      setTimeout(() => {
        this.loadingImageFile = false;
      }, 300);
    },
    beforeAvatarUpload(file: any) {
      this.loadingImageFile = true;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.loadingImageFile = false;
        ElMessage.error((this as any).$t("popup.message.message_5e"));
      }
      if (!isLt2M) {
        this.loadingImageFile = false;
        ElMessage.error((this as any).$t("popup.message.message_6e"));
      }

      return isLt2M && isJPG;
    },
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    async submitFrom() {
      const file = this.form.companyUploadedFile;
      const dataUrl = this.form.companyUploadedFileDataUrl;
      // Can't delete property from object with typescript
      const userDetails = _.omit(this.form, [
        "companyUploadedFile",
        "companyUploadedFileDataUrl",
        "imageFile",
      ]);

      const createUser = async (userDetails: any) => {
        // console.log("createUser()");
        // console.log(userDetails, "userDetails");
        this.$store.dispatch("auth/setSignupBranding", userDetails, {
          root: true,
        });
        let $userDetails = _.omit(
          this.$store.getters["auth/signupFormDetails"] || {},
          ["companyUploadedFile", "companyUploadedFileDataUrl", "imageFile"]
        );
        // console.log($userDetails, "$userDetails");
        // if ($userDetails) return;
        this.$store.commit("loadingStatus", true);

        const referralCode = helpers.getReferralCode();
        if (referralCode) {
          $userDetails["referral"] = referralCode;
        }

        await this.$store.dispatch("user/createUser", $userDetails, {
          root: true,
        });

        // binding OAuth Account
        const ns = "CreateAccountForm";
        const oAuthProvider = this.$store.getters[ns + "/oAuthProvider"];
        const oAuthResult = this.$store.getters[ns + "/oAuthResult"];
        if (oAuthProvider && oAuthResult) {
          const oAuthDispatchType = oAuthProvider + "/upsertAccount";
          await this.$store.dispatch(oAuthDispatchType, oAuthResult);
        }

        this.$store.dispatch("auth/clearSignupFormDetails", null, {
          root: true,
        });

        const callback = () => {
          const redirectUrl = this.$store.getters["auth/redirectUrl"] || "/";
          const delay = referralCode ? 5000 : 1500;
          setTimeout(() => {
            this.$store.commit("loadingStatus", false);
            window.location.href = redirectUrl;
          }, delay);
        };
        this.$store.dispatch(
          "auth/loginAsUser",
          { email: $userDetails.email, callback: callback },
          {
            root: true,
          }
        );
      };

      // @note upload by string (data url)
      if (dataUrl && file) {
        const storage = getStorage();
        const { email } = this.$store.getters["auth/signupFormDetails"] || {};
        const emailKey = helpers.emailKey(email);
        if (file instanceof File) {
          const prefix = "company";
          const guid = helpers.guid();
          let filename = file.name;
          if (filename.indexOf(".") !== -1) {
            const chunks = filename.split(".");
            const ext = chunks.pop();
            filename = chunks.join(".") + "-" + guid + "." + ext;
          }
          const path = `images/users/${emailKey}/${prefix}-${filename}`;
          const storageRef = ref(storage, path);
          uploadString(storageRef, dataUrl, "data_url").then(
            async (snapshot) => {
              userDetails.companyLogoUrl = snapshot.metadata.fullPath;
              await createUser(userDetails);
            }
          );
        }
      } else {
        await createUser(userDetails);
      }
    },
  },
});
